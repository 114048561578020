<template>
  <div class="about-page">
    <!-- <div class="download-links">
      <a href="/Abagael Cheng Résumé - February 2022.pdf" download><span>RÉSUMÉ</span></a>
      <a href="/Abagael Cheng Headshot.jpeg" download><span>HEADSHOT</span></a>
      <a href="/Abagael Cheng Bio.pdf" download><span>BIO</span></a>
    </div> -->
    <div class="about-title">
      <h2 v-bind:style="windowWidth < 768 ? { top: 0 } : { top: slideTitle + 'px' }"> ABAGAEL CHENG </h2>
      <h3 class="t1" v-bind:style="windowWidth < 768 ? { top: 0 } : { top: slideSubtitle + 'px' }">PERFORMER</h3>
      <!-- <h3 class="t2">SINGER</h3>
      <h3 class="t3">ARTIST</h3> -->
    </div>
    <div class="about-image">
      <img :src="require('@/assets/images/abby-about.jpg')">
    </div>
    <div class="about-text" :style="{ top: slideText + 'px' }">
      <p>
        Chinese American soprano Abagael Cheng (she/her) is a singing artist, actor, producer, and activist who strives to break the boundaries of classical performance and move audiences toward social activism. Cheng sees her role in social justice and the arts to be one and the same: shifting culture through storytelling. She aims to deepen relationships within and between communities by engaging audiences with politically relevant narratives and centering collaboration in her productions. Most recently, Cheng co-created, co-produced, and performed her theatrical chamber recital, <em>Awakening</em>, as part of City Lyric Opera's 2024 artist-led season. Inspired by the tradition of protest music, <em>Awakening</em> combines song, chamber music, spoken poetry, and movement to explore the question: what does freedom look like to you? Her next original interdisciplinary production, held this spring at Culture Lab LIC, will explore how folks from the AANHPI diaspora discover creative ways to establish new connections to their lost heritage and ancestral culture.
      </p>
      <p>
        A 2024 grant recipient of the Puffin Foundation and the The Anna Sosenko Assist Trust, Cheng won first place in the voice division of the 2023 Delta Symphony Orchestra Young Artist Competition and was awarded the Penelope Davis Hart Honorable Mention at The Philharmonic Society of Arlington 2024 Young Artist Competition. An enthusiast of contemporary music, she has participated in the development and performance of operatic works-in-progress, such as <em>She Breathes Fire</em> by Stacy Busch with The Why Collective and <em>The Jade Star Hotel</em> by Stephanie Chou and Susan Kander with The American Opera Project. This coming spring, Cheng will perform as a soprano soloist in City Lyric Opera's production of <em>Savior</em> by Amy Beth Kirsten.
      </p>
      <p>
        During the 2024 season, Cheng appeared in the Fairy Chorus of <em>Le Dernier Sorcier</em> by Viardot at Bard Music Festival. Previous performance highlights include her appearances as the Cabaret Soloist in the world premiere of <em>The Final Veil</em> by JL Marlor at the cell theatre, as Chocholka in Janáček's <em>The Cunning Little Vixen</em> at The Fisher Center at Bard, and as a soprano soloist in <em>Messages of the late R. V. Troussova</em> at Bard College's annual Kurtág Festival. A New York City-based artist, she holds a Bachelor of Music in Vocal Performance and a Bachelor of Arts in Mathematics from Oberlin College & Conservatory. She recently completed her Master's degree in the Graduate Vocal Arts Program at Bard College Conservatory of Music. In addition to her musical pursuits, Cheng is a world champion ultimate frisbee player and will compete next fall on the U.S.A. National Mixed Team at the World Beach Ultimate Championships in Portugal. She has won gold medals in the sport at the national and international levels. 
      </p>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: 'About',
    data: function () {
      return {
        windowWidth: window.innerWidth,
        slideTitle: '120',
        slideSubtitle: '250',
        slideText: '350'
      }
    },
    methods: {
      handleScroll() {
        this.slideTitle = Math.max(120 - 0.7*window.scrollY, 0);
        this.slideSubtitle = Math.max(250 - 0.6*window.scrollY, 0);
        this.slideText = Math.max(350 - 0.5*window.scrollY, 0);
      },
      onResize() { this.windowWidth = window.innerWidth; }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.onResize);
    },
    unmounted () {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.onResize);
    },
  }
  </script>
  
  <style lang="scss">
  $content-base: 13%;
  
  .about-page {
    width: 100%;
    height: 100vh;
    padding-top: 125px;
    position: absolute;
    color: #191E23;
  }
  
  .about-image img {
    width: 100%;
    top: $content-base + 10%;
    position: absolute;
    z-index: -1;
  }
  
  .about-title {
    width: 100%;
  
    h2 {
      width: 100%;
      position: relative;
      margin: 0;
  
      font-size: 10vw;
      line-height: 10vw;
      font-family: "Archivo Black";
    }
  
    h3 {
      width: 100%;
      position: relative;
      margin: 0;
  
      font-size: 8vw;
      line-height: 8vw;
      text-align: right;
    }
  }
  
  .about-text {
    width: 90%;
    margin-top: 800px;
    padding: 0px 20px;
  
    font-size: 12pt;
  }
  
  @media only screen and (min-width: 768px) {
    .about-page {
      padding-top: 175px;
      position: absolute;
    }
  
    .about-image img {
      width: 40%;
      top: $content-base + 10%;
    }
  
    .about-title {
      h2 {
        position: absolute;
      }
  
      h3 {
        position: absolute;
      }
    }
  
    .about-text {
      width: 50%;
      position: absolute;
      margin-top: 100px;
      margin-left: 43%;
  
      font-size: 14pt;
    }
  }
  
  // .download-links {
  //   position: absolute;
  //   z-index: 200;
  //   bottom: 0;
  //   padding: 40px 40px;
  
  //   a {
  //     text-decoration: none;
  //     color: #D3D0CB;
  //     padding: 10px;
  //     border: 1px solid #D3D0CB;
  //     border-radius: 20px;
  //     margin-right: 10px;
  //     transition: all 0.5s;
  //     cursor: pointer;
  
  //     span {
  //       cursor: pointer;
  //       display: inline-block;
  //       position: relative;
  //       transition: 0.5s;
  //     }
  
  //     span:after {
  //       content: "";
  //       background-image: url('~@/assets/icons/download.png');
  //       background-size: 16px 16px;
  //       background-repeat: no-repeat;
  //       height: 16px;
  //       display: inline-block;
  //       position: absolute;
  //       opacity: 0;
  //       transition: 0.5s;
  //       top: 3px;
  //     }
  
  //     &:hover span {
  //       padding-right: 20px;
  //     }
  
  //     &:hover span:after {
  //       width: 16px;
  //       opacity: 1;
  //       right: 0;
  //     }
  //   }
  // }
  </style>