<template>
  <div class="media-page">
    <div class="media-image__wrapper">
      <img :src="require('@/assets/images/abby-media.jpg')" class="media-image" />
    </div>
    <div class="media-container"> 
      <div class="video-container">
        <div class="video">
          <iframe src="https://www.youtube.com/embed/_f2RlWFP_PA?si=lxato-VQ01LuXjM6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p><i>Ach ich fühl's</i> by Mozart</p>
        </div>
        <div class="video">
          <iframe src="https://www.youtube.com/embed/Y9T_QnIEuTc?si=5FrV-EbivydagwqQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p><i>Hannelore</i> by Horst Platen</p>
        </div>
      </div>
      <div class="video-container">
        <div class="video">
          <iframe src="https://www.youtube.com/embed/YEq-qUs0uRM?si=XBt-J3WP3107NNND" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p><i>阳关三叠 [Yáng Guān Sān Dié]</i> arr. by 黄永熙 [Huáng Yǒng Xī]</p>
        </div>
        <div class="video">
          <iframe src="https://www.youtube.com/embed/eDobJ4LliK4?si=ze5Cd_hT_bTd5AiP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p><i>A Horse With Wings</i> by Ricky Ian Gordon</p>
        </div>
      </div>
      <div class="video-container">
        <div class="video">
          <iframe src="https://www.youtube.com/embed/N7l1eeFRCeo?si=feKXKunmz2d7YkRF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p><i>Be Kind and Courteous</i> by Britten</p>
        </div>
        <div class="video">
          <iframe src="https://www.youtube.com/embed/Q9pg9fyvP9c?si=KRArRcgiP0N7gbrS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p><i>I Know My Mind</i> by Margaret Bonds</p>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: 'Media',
    data: function () {
      return {
        images: [
          // "IMG5786.jpg",
          // "IMG3539.jpg",
          // "gallery1.jpg",
          // "IMG5781.jpg",
          // "IMG5785.jpg",
        ]
      }
    },
  }
  </script>
  
  
  <style lang="scss" scoped>
  .media-page {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .media-image__wrapper {
    padding: 80px 30px 0px 30px;
  }
  .media-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: 90% center;
  }
  .media-container {
    padding: 30px;
    max-width: 100%;
  }
  .video-container {
    .video {
      iframe {
        width: 98%;
        min-height: 320px;
      }
    }
  }
  
  .masonry-image {
    width: 300px;
    padding-bottom: 5px;
  }
  
  @media only screen and (min-width: 768px) {
    .media-image__wrapper {
      padding: 80px 100px 0px 100px;
    }
    .media-container {
      padding: 80px 100px;
      max-width: 100%;
    }
    .video-container {
    display: flex;
  
    .video {
      flex: 1;
  
      iframe {
        width: 98%;
        min-height: 320px;
      }
    }
  }
  }
  </style>